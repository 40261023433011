import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import BigHeading from '../components/BigHeading'
import {
  mobileVW,
  desktopVW,
  mobileBreakpoint,
  desktopBreakpoint,
  colors,
  quad,
  cubic,
  quart,
  back,
} from '../styles'
import Image from 'gatsby-image'
import RichText from '../components/RichText'
import Subscribe from '../components/Subscribe'
import SEO from '../components/SEO'
import useSticky from '../hooks/window/useSticky'
import { useRecoilValue } from 'recoil'
import { startPageAnimation } from '../atoms'

const StyledStory = styled.section`
  margin: ${desktopVW(250)} ${desktopVW(100)} ${desktopVW(200)};
  ${mobileBreakpoint} {
    div:nth-child(2) {
      margin-top: ${mobileVW(20)};
    }
  }
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${desktopBreakpoint} {
    flex-direction: row;
    justify-content: space-between;
    margin: ${desktopVW(200)} 0 ${desktopVW(100)};
    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
  }
`
const Column = styled.div`
  flex: 0 0 49%;
`
const TextWrapper = styled.div`
  margin: ${mobileVW(40)} 0 ${mobileVW(40)};
  p {
    font-size: ${mobileVW(16)};
    line-height: ${mobileVW(26)};
    margin-bottom: ${mobileVW(20)};
  }
  b {
    font-size: ${mobileVW(20)};
    line-height: ${mobileVW(26)};
    font-weight: 300;
    margin-bottom: ${mobileVW(40)};
    display: block;
  }
  h2 {
    font-size: ${mobileVW(60)};
    line-height: ${mobileVW(60)};
    margin-bottom: ${mobileVW(40)};
    display: block;
  }
  ${desktopBreakpoint} {
    margin: ${desktopVW(400)} ${desktopVW(60)} ${desktopVW(400)};
    p {
      font-size: ${desktopVW(20)};
      line-height: ${desktopVW(30)};
      margin-bottom: ${desktopVW(20)};
    }
    b {
      font-size: ${desktopVW(25)};
      line-height: ${desktopVW(35)};
      font-weight: 300;
      margin-bottom: ${desktopVW(40)};
      display: block;
    }
    h2 {
      font-size: ${desktopVW(90)};
      line-height: ${desktopVW(100)};
      margin-bottom: ${desktopVW(40)};
      display: block;
    }
  }
`

const ImageWrapper = styled.div`
  margin: 0 auto;
  margin-top: ${mobileVW(30)};
  ${desktopBreakpoint} {
    margin-top: 0;
    top: 0;
    position: absolute;
    width: 40vw;
    height: 100vh;
    max-height: 80vw;
    overflow: hidden;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`

const Story = ({
  data: {
    contentfulStoryPage: {
      title,
      why,
      whyImage,
      how,
      howImage,
      what,
      whatImage,
    },
  },
}) => {
  const [sectionImageRef, ref] = useSticky(false)
  const [sectionImage1Ref, ref1] = useSticky(false)
  const [sectionImage2Ref, ref2] = useSticky(false)
  const appear = useRecoilValue(startPageAnimation)

  return (
    <>
      <SEO title={`OUR STORY | ASK PHILL VENTURES`} />
      <StyledStory>
        <BigHeading visible={appear}>{title}</BigHeading>
        <Section ref={ref}>
          <Column>
            <ImageWrapper ref={sectionImageRef}>
              <Image
                fluid={whyImage.fluid}
                backgroundColor={colors.backgroundImage}
              />
            </ImageWrapper>
          </Column>
          <Column>
            <TextWrapper>
              <RichText json={why.json} />
            </TextWrapper>
          </Column>
        </Section>
        <Section ref={ref1}>
          <Column>
            <ImageWrapper ref={sectionImage1Ref}>
              <Image
                fluid={howImage.fluid}
                backgroundColor={colors.backgroundImage}
              />
            </ImageWrapper>
          </Column>
          <Column>
            <TextWrapper>
              <RichText json={how.json} />
            </TextWrapper>
          </Column>
        </Section>
        <Section ref={ref2}>
          <Column>
            <ImageWrapper ref={sectionImage2Ref}>
              <Image
                fluid={whatImage.fluid}
                backgroundColor={colors.backgroundImage}
              />
            </ImageWrapper>
          </Column>
          <Column>
            <TextWrapper>
              <RichText json={what.json} />
            </TextWrapper>
          </Column>
        </Section>
      </StyledStory>
      <Subscribe />
    </>
  )
}

export const PageQuery = graphql`
  query Story($id: String!) {
    contentfulStoryPage(id: { eq: $id }) {
      title
      why {
        json
      }
      whyImage {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      how {
        json
      }
      howImage {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      what {
        json
      }
      whatImage {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default Story
